import { render, staticRenderFns } from "./RecSuccess.vue?vue&type=template&id=0c793146&scoped=true&"
var script = {}
import style0 from "./RecSuccess.vue?vue&type=style&index=0&id=0c793146&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c793146",
  null
  
)

export default component.exports